<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav">
      <el-tab-pane label="标签管理" name="first">
        <!-- 搜索查询-->
        <div class="search space-between" style="margin: 15px 0 30px 0">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="名称" class="search-type">
              <el-option v-for="item in optionsData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-input v-model="keywords" placeholder="请输入内容" class="search-input"></el-input>

            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
          <el-button type="warning" class="f-r search-add" @click="add()">新增标签</el-button>
        </div>

        <div class="total vertical-center">
          <div class="total-left">
            共有 <span class="total-left-text">{{ tagsInfo.meta ? (tagsInfo.meta.total > 9999 ? (tagsInfo.meta.total / 10000).toFixed(2) + 'W' : tagsInfo.meta.total) : 0 }}</span> 条数据
          </div>
          <el-button type="text" class="total-right vertical-center" @click="batchDel" v-if="tagsData.length">
            <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom;" class="del" />
            <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom;" class="del-hover" />
            <span style="margin-left: 8px">批量删除</span>
          </el-button>
        </div>

        <!-- 表格-->
        <div v-if="tagsData.length">
          <el-table
            class="table"
            ref="multipleTable"
            :data="tagsData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
            border
          >
            <el-table-column type="selection" :selectable="checkSelecTable" label="全选" width="90" align="center"> </el-table-column>
            <el-table-column type="index" label="编号" style="color: #008aff" width="120"> </el-table-column>
            <el-table-column prop="name" label="名称" show-overflow-tooltip> </el-table-column>
            <el-table-column label="状态" width="90">
              <template slot-scope="scope">
                <span class="table-type table-cg" v-if="scope.row.status === 0">草稿</span>
                <span class="table-type table-num" v-else-if="scope.row.status === 1">发布</span>
                <span class="table-type " v-else-if="scope.row.status === -1">关闭</span>
              </template>
            </el-table-column>

            <el-table-column label="创建时间" width="200">
              <template slot-scope="scope"
                ><span class="table-num gray-font-color">{{ scope.row.created_at }}</span></template
              >
            </el-table-column>

            <el-table-column label="操作" align="center" width="110">
              <template slot-scope="scope">
                <div class="align-center">
                  <el-button type="text" size="small" class="table-edit" @click="edit(scope.row)">编辑</el-button>
                  <span style="color: #D0D6DE;margin: 0 8px">|</span>
                  <el-dropdown trigger="click" @command="putTags($event, scope.row)" class="table-edit" placement="bottom">
                    <span class="el-dropdown-link  cursor-pointer">
                      更多
                      <!--                    <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="up" class="table-text-gray" v-if="scope.row.status === 0">发布</el-dropdown-item>
                      <el-dropdown-item command="open" class="table-text-gray" v-if="scope.row.status === -1">发布</el-dropdown-item>
                      <el-dropdown-item command="close" class="table-text-gray" v-if="scope.row.status === 1">关闭</el-dropdown-item>
                      <el-dropdown-item class="table-text-gray" v-if="scope.row.status === -1 || scope.row.status === 0" command="del">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty-prompt style="margin-top: 120px;" v-else></empty-prompt>
        <!--  分页  -->
        <el-pagination
          v-if="tagsInfo.meta && tagsInfo.meta.last_page > 1"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          :total="tagsInfo.meta && tagsInfo.meta.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!--    新增修改弹框-->
    <el-dialog
      class="dialog"
      :title="isAdd ? '新增标签' : '修改标签'"
      :visible.sync="isDialogVisible"
      v-if="isDialogVisible"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <!--  表单-->
      <el-form label-position="left" label-width="60px" class="form" :rules="rules" :model="nameform">
        <el-form-item label="名称" prop="name">
          <el-input v-model="nameform.name" maxlength="10" placeholder="请输入标签名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="tagPut">确 定</el-button>
      </span>
    </el-dialog>
    <!--    删除弹框-->
    <el-dialog class="delDiolog" :visible.sync="isdelDialogVisible" top="calc( 50vh - 150px )" icon="el-icon-warning" :destroy-on-close="true" width="360px" center>
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>{{ isdelDialogVisibleText }}</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import EmptyPrompt from 'components/EmptyPrompt';
export default {
  name: 'Index',
  data() {
    return {
      isAdd: true,
      isDialogVisible: false,
      isdelDialogVisible: false,
      activeName: 'first',
      optionsData: [
        {
          label: '名称',
          value: 'name',
        },
      ],
      rules: {
        name: [{ required: true, message: '请填写名称', trigger: 'blur' }],
      },
      nameform: {
        name: '',
      },
      options: '', // 选项值
      keywords: '', // 输入文本值
      page: 1, // 分页数
      pageSize: 20, // 每页显示条数
      tagsData: [], // 标签数据
      tagsInfo: {}, // 数据信心
      multipleSelection: [],
      name: '', // 标签名称
      current_item: {}, // 点击当前元素
      isdelDialogVisibleText: '', //修改提示文本
      put_tags_status: '', // 当前选择按钮
    };
  },
  created() {
    this.queryFun();
    this.getTags();
  },
  methods: {
    // 获取标签数据
    getTags() {
      let url = this.$api.Tags + `?options=${this.options}&keywords=${this.keywords}&page=${this.page}&pageSize=${this.pageSize}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.tagsInfo = res.data.data;
          this.tagsData = this.tagsInfo.data;
        }
      });
    },
    // 筛选查询
    searchSubmit() {
      this.page = 1;
      this.linkFun();
      this.getTags();
    },
    // 重置
    resetFun() {
      this.options = '';
      this.keywords = '';
      this.searchSubmit();
    },
    changeCellStyle(row) {
      if (row.column.label === '编号') {
        return 'color:#008aff';
      }
    },
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.linkFun();
      this.getTags();
    },
    //弹窗关闭
    handleClose(done) {
      done();
    },
    //新增标签
    add() {
      this.nameform = {
        name: '',
      };
      this.isAdd = true;
      this.isDialogVisible = true;
    },
    //修改标签
    edit(item) {
      this.current_item = item;
      this.nameform.name = item.name;
      this.isAdd = false;
      this.isDialogVisible = true;
    },
    // 标签操作
    tagPut: _.debounce(function() {
      let url = this.$api.Tags;
      if (this.nameform.name.trim() == '') {
        this.$message.error('标签不能为空');
        return false;
      }
      if (this.isAdd) {
        let data = this.nameform;
        this.$http
          .post(url, data, true)
          .then((res) => {
            if (res.data.success) {
              this.$message.success('增加成功');
              this.getTags();
              this.isDialogVisible = false;
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      } else {
        let data = {
          name: this.nameform.name,
        };
        this.$http
          .put(url + '/' + this.current_item.id, data, true)
          .then((res) => {
            if (res.data.success) {
              this.$message.success('修改成功');
              this.getTags();
              this.isDialogVisible = false;
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      }
    }, 500),
    //删除标签
    del(item) {
      this.current_item = item;
      this.isdelDialogVisible = true;
    },
    // 删除确定
    delTagFun() {
      let url = this.$api.Tags + '/' + this.current_item.id;
      this.$http.del(url, true).then((res) => {
        if (res.data.success) {
          this.$message.success('删除成功');
          this.getTags();
          this.isdelDialogVisible = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 批量删除
    batchDel: _.debounce(function() {
      if (this.multipleSelection.length) {
        this.isdelDialogVisible = true;
        this.put_tags_status = 'batch_del';
      }
    }, 100),
    batchDelFun() {
      let ids = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id);
      }
      let url = this.$api.batchDestroy;
      this.$http
        .post(url, { tag_ids: ids }, true)
        .then((res) => {
          if (res.data.success) {
            this.isdelDialogVisible = false;
            this.$message.success('删除成功');
            this.multipleSelection = [];
            this.getTags();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    // 修改标签状态
    putTags(command, item) {
      this.current_item = item;
      this.put_tags_status = command;
      this.isdelDialogVisible = true;
      if (command === 'del') {
        this.isdelDialogVisibleText = '你确定要删除该标签么？';
      } else if (command === 'up') {
        this.isdelDialogVisibleText = '你确定要发布该标签么？';
      } else if (command === 'close') {
        this.isdelDialogVisibleText = '你确定要关闭该标签么？';
      } else if (command === 'open') {
        this.isdelDialogVisibleText = '你确定要发布该标签么？';
      }
    },
    // 请求修改变迁状态
    putTagsStatus() {
      let url = this.$api.Tags + '/' + this.current_item.id + this.$api.changeStatus;
      this.$http
        .get(url, true)
        .then((res) => {
          if (res.data.success) {
            this.$message.success('修改成功');
            this.isdelDialogVisible = false;
            this.getTags();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    // 修改标签确定提交
    putTagStatus() {
      if (this.put_tags_status == 'del') {
        this.delTagFun();
      } else if (this.put_tags_status === 'up' || this.put_tags_status === 'close' || this.put_tags_status === 'open') {
        this.putTagsStatus();
      } else if (this.put_tags_status === 'batch_del') {
        this.batchDelFun();
      }
    },
    // 判断是否可以选中
    checkSelecTable(row) {
      return row.status !== 1;
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({
        path: '/tag',
        query: {
          options: this.options,
          keywords: this.keywords,
          page: this.page,
          pageSize: this.pageSize,
        },
      });
    },
    // 参数赋值
    queryFun() {
      let query = this.$route.query;
      if (query) {
        if (query.options) {
          this.options = query.options;
        }
        if (query.keywords) {
          this.keywords = query.keywords;
        }
        if (query.page) {
          this.page = parseInt(query.page);
        }
        if (query.pageSize) {
          this.pageSize = parseInt(query.pageSize);
        }
      }
    },
  },
  components: {
    EmptyPrompt,
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/tag/index.less';
</style>
